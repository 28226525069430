const GameLists = [
  { text: 'PGSLOT', value: 'slot.pgslot' },
  { text: 'PGSOFT2', value: 'slot.pgsoft2' },
  { text: 'JOKER', value: 'slot.joker' },
  { text: 'AMBPOKER', value: 'slot.ambpoker' },
  { text: 'RICH88', value: 'slot.rich88' },
  { text: 'SPADEGAMING', value: 'slot.spadegaming' },
  { text: 'ICONICGAMING', value: 'slot.iconicgaming' },
  { text: 'FUNTAGAMING', value: 'slot.funtagaming' },
  { text: 'TOPGRADEGAMES', value: 'slot.topgradegames' },
  { text: 'YGGDRASILDIRECT', value: 'slot.yggdrasildirect' },
  { text: 'SLOTMILL', value: 'slot.slotmill' },
  { text: 'JUSTPLAY', value: 'slot.justplay' },
  { text: 'MEN5GAMING', value: 'slot.men5gaming' },
  { text: 'ALLWAYSPIN', value: 'slot.allwayspin' },
  { text: 'HABANEROGAMING', value: 'slot.habanerogaming' },
  { text: 'FACHAI', value: 'slot.fachai' },
  { text: 'SIMPLEPLAY', value: 'slot.simpleplay' },
  { text: 'WAZDAN', value: 'slot.wazdan' },
  { text: 'RELAXGAMING', value: 'slot.relaxgaming' },
  { text: 'QUICKSPIN', value: 'slot.quickspin' },
  { text: '1X2GAMING', value: 'slot.1x2gaming' },
  { text: 'NOLIMITCITY', value: 'slot.nolimitcity' },
  // { text: 'PLAYNGO', value: 'slot.playngo' },
  { text: 'BLUEPRINTGAMING', value: 'slot.blueprintgaming' },
  { text: 'BOOONGO', value: 'slot.booongo' },
  { text: 'JILI', value: 'slot.jili' },
  { text: 'KAGAMING', value: 'slot.kagaming' },
  { text: 'MANNAPLAY', value: 'slot.mannaplay' },
  { text: 'FUNKYGAMES', value: 'slot.funkygames' },
  { text: 'EVOPLAY', value: 'slot.evoplay' },
  { text: 'PRAGMATICPLAY', value: 'slot.pragmaticplay' },
  { text: 'KINGMAKER', value: 'slot.kingmaker' },
  { text: 'AEGAMING', value: 'slot.aegaming' },
  { text: 'CQ9', value: 'slot.cq9' },

  // casino
  { text: 'SEXY', value: 'casino.sexy' },
  { text: 'DREAMGAME', value: 'casino.dreamgame' },
  { text: 'PRETTYGAMING', value: 'casino.prettygaming' },
  { text: 'SAGAME', value: 'casino.sa' },
  { text: 'ALLBET', value: 'casino.allbet' },
  { text: 'EBET', value: 'casino.ebet' },
  { text: 'CASINO_PRAGMATICPLAY', value: 'casino.casino_pragmaticplay' },
  { text: 'CASINO_CQ9', value: 'casino.casino_cq9' },
  { text: 'WECASINO', value: 'casino.wecasino' },
  { text: 'MICRO_LIVECASINO', value: 'casino.micro_livecasino' },
  { text: 'BETGAME', value: 'casino.betgame' },
  { text: 'AGGAME', value: 'casino.aggame' },
  // end casino

  { text: 'ACE333', value: 'slot.ace333' },
  { text: 'SPINIX', value: 'slot.spinix' },
  { text: 'KISS918', value: 'slot.kiss918' },
  { text: 'NETENT', value: 'slot.netent' },
  { text: 'REDTIGER', value: 'slot.redtiger' },
  { text: 'NEXTSPIN', value: 'slot.nextspin' },
  { text: 'NINJA', value: 'slot.ninja' },
  { text: 'ADVANT', value: 'slot.advant' },
  { text: 'ASKMEPLAY', value: 'slot.askmeplay' },
  { text: 'BOLEBIT', value: 'slot.bolebit' },
  { text: 'WMSLOT', value: 'slot.wmslot' },

  // sport
  { text: 'SBO', value: 'sport.sbo' },
  { text: 'M8', value: 'sport.m8' },
  { text: 'M8LIVE', value: 'sport.m8live' },
  { text: 'M8STEP', value: 'sport.m8step' },
  { text: 'XCOCKFIGHT', value: 'sport.xcockfight' },

  // esport
  { text: 'XESPORT', value: 'esport.xesport' },

  // lottery
  { text: 'XLOTTERY', value: 'lottery.xlottery' },
  { text: 'ISMLOTTO', value: 'lottery.ismlotto' },
]

// eslint-disable-next-line import/prefer-default-export
export { GameLists }
